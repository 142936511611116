import React, { useEffect, useState } from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { 
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import {
    sf,
    misc,
} from '../utils';
import _ from 'lodash';
import { FilmInfo, withAll } from '../state';
import {
    withFormOptionsState,
    connect,
    useFilmInfo,
} from '../state';
import { 
    Manufacturer, 
    Product 
} from '../types';
import * as catModel from '../state/catModel';
import ManufacturerAutoComplete from './ManufacturerAutoComplete';
import ProductAutoComplete from './ProductAutoComplete';
import GroupAutoComplete from './handTest/GroupAutoComplete';
import { withHandTestFormState } from '../state/handTest/selectors';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(2),
    },
}));

interface Props {
    filmInfoStrId: string;
    productManufacturers: Manufacturer[];
    isHandTest: boolean;
    products: Product[];
    handProducts: Product[];
};

const FilmProductInfoForm:React.FC<Props> = (props:Props) => {
    const {
        filmInfoStrId,
        productManufacturers,
        isHandTest,
        products,
        handProducts,
    } = props;
    const [ _filmInfo, setFilmInfo ] = useFilmInfo( filmInfoStrId );
    const classes = useStyles();

    const currentProducts = isHandTest ? handProducts : products;

    const [ availableManufacturers, setFilteredManufacturers ] = useState([]);

    useEffect(() => {
        const manufacturersIds:number[] = currentProducts.reduce(
            (uniques, item) => uniques.includes(item.manufacturer.id)
                ? uniques
                : [...uniques, item.manufacturer.id],
                []
            );

        const filteredManufacturers:Manufacturer[] = productManufacturers.filter(
            (manufacturer:Manufacturer) => manufacturersIds.some(
                (id:number) => manufacturer.id === id)
            );

        setFilteredManufacturers(filteredManufacturers);
    }, [currentProducts, productManufacturers]);

    if ( !!! _filmInfo ) {
        return null;
    }

    return (
        <Box>
            <Typography className={classes.heading} component="h6" variant="h6">
                FILM { _filmInfo.sequenceNumber }
            </Typography>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            label="Description"
                            name="description"
                            value={ _filmInfo.description }
                            inputProps={{
                                maxLength: 50,
                            }}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'description' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                    { _filmInfo.sequenceNumber === 1
                        ?   <ManufacturerAutoComplete
                                value={ _filmInfo.productManufacturer }
                                manufacturers={ availableManufacturers }
                                onChange={ 
                                    _.flow(
                                        sf.lens( 'target.value' ).get,
                                        sf.lens( 'productManufacturer' ).extend( _filmInfo ),
                                        sf.tap( setFilmInfo ),
                                    )
                                }
                            />
                        :   <TextField
                                label="Manufacturer"
                                name="productManufacturer"
                                value="Paragon Films"
                                disabled
                            />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <ProductAutoComplete
                            value={ _filmInfo.productName }
                            products={
                                _.filter( 
                                    currentProducts, 
                                    (product:Product) => product.manufacturer.name === _filmInfo.productManufacturer
                                )
                            }
                            onChange={ 
                                _.flow(
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'productName' ).extend( _filmInfo ),
                                    (filmInfo:FilmInfo) => {
                                        const product:Product = _.find( 
                                            currentProducts, 
                                            (product:Product) => product.name === filmInfo.productName
                                        );
                                        return _.isNil( product )
                                            ? filmInfo
                                            : _.chain(filmInfo)
                                                .thru( sf.lens( 'productGroup' ).set( product.group ) )
                                                .thru( sf.lens( 'gauge' ).set( product.gauge ) )
                                                .thru( sf.lens( 'width' ).set( product.width ) )
                                                .thru( sf.lens( 'length' ).set( product.length ) )
                                                .thru( sf.lens( 'product' ).set( product ) )
                                                .thru( sf.lens( 'netPolyWeight' ).set( product.netPolyWeight ) )
                                                .value()
                                            ;
                                    }
                                    ,
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <GroupAutoComplete
                        value={ _filmInfo.productGroup }
                        groups={ _.uniqBy(currentProducts, function (product:Product){
                                if (!_.isEmpty(_filmInfo.productManufacturer) && product.manufacturer.name === _filmInfo.productManufacturer) return product.group;
                            })
                            .map(x => x.group)
                        }
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt(50),
                                sf.lens( 'productGroup' ).extend( _filmInfo ),
                                sf.tap( setFilmInfo ),
                            )
                        }
                    />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Gauge"
                            name="gauge"
                            type="number"
                            value={ _filmInfo.gauge }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'gauge' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Width"
                            name="width"
                            type="number"
                            value={ misc.numberToString( _filmInfo.width ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'width' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Footage"
                            name="length"
                            type="number"
                            value={ misc.numberToString( _filmInfo.length ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'length' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Roll Price"
                            name="rollPrice"
                            type="number"
                            value={ misc.numberToString( _filmInfo.rollPrice ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'rollPrice' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};


export default connect(
    withAll( withFormOptionsState, withHandTestFormState ),
)( FilmProductInfoForm );