
import React from 'react';
import ExpansionPanel from '../../material/ExpansionPanel';
import FormGeneralInfo from './FormGeneralInfo';
import Box from '@material-ui/core/Box';
import { Theme, makeStyles } from '@material-ui/core/styles';
import FormCustomerInfo from './FormCustomerInfo';
import FormFilmTestConfig from './FormFilmTestConfig';

const useStyles = makeStyles((theme: Theme) => ({
    backgroundGrey: {
        backgroundColor: '#e5e5e5',
    },
}));

const useExpansionPanelStyles = makeStyles((theme: Theme) => ({
    root: {
        '&$expanded': {
            margin: 0,
        }
    },
    expanded: {
        margin: 0,
    },
}));

interface Props {
    isSavedTest:boolean;
};

const Tabs:React.FC<any> = (props:Props) => {
    const classes = useStyles();
    const expansionPanelClasses = useExpansionPanelStyles();
    
    return (
        <Box>
            <ExpansionPanel 
                title="Test Information" 
                defaultExpanded={true}
                classes={expansionPanelClasses}
                >
                <FormGeneralInfo />
            </ExpansionPanel>
            <ExpansionPanel 
                title="Customer Information" 
                className={classes.backgroundGrey}
                classes={expansionPanelClasses}
                >
                <FormCustomerInfo />
            </ExpansionPanel>
            <ExpansionPanel 
                title="Pallet Information" 
                classes={expansionPanelClasses}
                >
                <FormFilmTestConfig
                    isSavedTest={ props.isSavedTest }
                />
            </ExpansionPanel>
        </Box>
    );
};

export default Tabs;
