/**
 * @rob4lderman
 * feb2020
 *
 * Loading page.
 */

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class LaunchPage extends React.Component<any, any> {

    render() {
        return (
            <div
                style={{ 
                    display: 'flex',
                    flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                    borderColor: 'pink', borderWidth: 1 ,
                    minHeight: '400px',
                }}
                >
                <CircularProgress variant="indeterminate" />
            </div>
        );
    }
}

export default LaunchPage;
