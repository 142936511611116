/**
 * @rob4lderman
 * feb2020
 */

import React from 'react';
import { 
    CircularProgress as MCircularProgress, 
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    colorSecondary: {
        // color: 'white',
        color: theme.palette.secondary.light,
    },
    circleIndeterminate: {
        fill: 'white',
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
}));

/**
 * This CircularProgress is used inside Buttons.
 */
const CircularProgress: React.FC = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root} position="absolute">
            <MCircularProgress 
                variant="indeterminate" 
                color="secondary"
                size={24}
                classes={{
                    colorSecondary: classes.colorSecondary,
                }}
            />
        </Box>
    );
};

export default CircularProgress;