import React from 'react';
import Divider from '@material-ui/core/Divider';
import ListIcon from '@material-ui/icons/List';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PanToolIcon from '@material-ui/icons/PanTool';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
    useLocation,
} from 'react-router-dom';
import {
    LoggerFactory,
} from '../utils';
const logger = LoggerFactory('DrawerMenu');

const useStyles = makeStyles((theme: Theme) => ({
    // this is a helper style for adjusting the page content
    // to accommodate the fixed-position AppBar.
    accommodateAppBar: theme.mixins.toolbar,
}));

const useListItemTextStyles = makeStyles((theme: Theme) => ({
    root: {
        textAlign: 'center',
    },
    body1: {
        lineHeight: 1.2,
        fontSize: 14,
    },
}));

const useListItemStyles = makeStyles((theme: Theme) => ({
    root: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        '&:first-child': {
            marginTop: -1,
        },
        '&$selected': {
            backgroundColor: theme.palette.primary.main,
        },
        '&$selected:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const DrawerMenu:React.FC<any> = (props:any) => {
    const {
        onClickSavedTests,
        onClickSavedHandTests,
        onClickUserProfile,
        onClickLogOut,
    } = props;
    const classes = useStyles();
    const listItemClasses = useListItemStyles();
    const listItemTextClasses = useListItemTextStyles();
    const location = useLocation();
    const theme:Theme = useTheme();
    logger.log( 'render', { location } );
    return (
        <Box 
            style={{ height: '100%' }} 
            bgcolor={theme.palette.text.primary} 
            color={'white'}
            >
            <div 
                className={classes.accommodateAppBar} 
            />
            <Divider />
            <List disablePadding={true}>
                <ListItem 
                    button 
                    key={'savedtests'}
                    selected={ location.pathname.includes('machine') } 
                    onClick={ onClickSavedTests }
                    classes={ listItemClasses }
                    disableGutters={true}
                    >
                    <ListIcon style={{ fontSize: 40 }}/>
                    <Typography classes={ listItemTextClasses }>
                        Machine 
                    </Typography>
                </ListItem>

                <ListItem 
                    button 
                    key={'handtests'}
                    selected={ location.pathname.includes('hand') } 
                    onClick={ onClickSavedHandTests }
                    classes={ listItemClasses }
                    disableGutters={true}
                    >
                    <PanToolIcon style={{ fontSize: 40 }}/>
                    <Typography classes={ listItemTextClasses }>
                        Hand 
                    </Typography>
                </ListItem>
                <ListItem 
                    button 
                    key={'userprofile'}
                    selected={ location.pathname === '/user-profile' } 
                    onClick={ onClickUserProfile }
                    classes={ listItemClasses }
                    disableGutters={true}
                    >
                    <PersonOutlineIcon style={{ fontSize: 40 }} />
                    <Typography classes={ listItemTextClasses }>
                        Profile
                    </Typography>
                </ListItem>
                <ListItem 
                    button 
                    key={'logout'}
                    onClick={ onClickLogOut }
                    disableGutters={true}
                    classes={ listItemClasses }
                    >
                    <ExitToAppIcon style={{ fontSize: 40 }} />
                    <Typography classes={ listItemTextClasses }>
                        Log Out
                    </Typography>
                </ListItem>
            </List>
        </Box>
    );
};

export default DrawerMenu;