import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './state/store';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import theme from './material/theme';
import AlertWindow from './comps/AlertWindow';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <App />
                <AlertWindow />
            </MuiPickersUtilsProvider>
        </Provider>
    </ThemeProvider>
    ,
    document.getElementById('root')
);
