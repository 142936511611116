/**
 * @rob4lderman
 * feb2020
 */
import React from 'react';
import MButton from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from './CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    label: {
        color: 'white',
    },
}));

interface Props {
    [key:string]: any;
    isLoading?: boolean;
};

const Button: React.FC<Props> = (props:Props) => {
    const {
        children,
        isLoading = false,
        ...rprops
    } = props;
    const classes = useStyles();
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <MButton
                type="button"
                variant="contained"
                color="primary"
                // -rx- className={classes.submit}
                classes={classes}
                disabled={isLoading}
                {...rprops}
                >
                { children }
            </MButton>
            { isLoading && <CircularProgress /> }
        </Box>
    );
};
 
export default Button;