/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    LoggerFactory,
} from '../utils';
import _ from 'lodash';
import { 
    Product
} from '../types';
const logger = LoggerFactory( 'ProductAutoComplete' );

interface Props {
    products: Product[];
    value: any;
    onChange: (event:any) => any;
};

const ProductAutoComplete:React.FC<Props> = (props:Props) => {
    const {
        products,
        value,
        onChange,
    } = props;
    logger.log( 'render', { props });
    return (
        <Autocomplete
            id="product"
            freeSolo
            selectOnFocus
            forcePopupIcon={true}
            options={products}
            groupBy={option => option.group }
            getOptionLabel={option => option.name}
            style={{ width: '100%'}}
            onInputChange={
                (event:any, value:string, reason:string) => {
                    logger.log( 'onInputChange', { event, value, reason });
                    onChange( { target: { value } } )
                }
            }
            renderInput={
                (params) => {
                    return (
                        <TextField 
                            {..._.merge( params, { inputProps: { value } } ) }
                            name="product"
                            label="Product" 
                            variant="outlined" 
                            fullWidth 
                            value={ value }
                            onBlur={ onChange }
                        />
                    )
                }
            }
        />
    )
};

export default ProductAutoComplete;