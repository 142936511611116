/**
 * @rob4lderman
 * feb2020
 *
 * Loading page.
 */

import React from 'react';
import LaunchPage from './LaunchPage';

const ViewPdfLoadingPage:React.FC<any> = (props:any) => {
    return <LaunchPage />;
};
 
export default ViewPdfLoadingPage;
