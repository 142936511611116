
import React from 'react';
import TextField from '../../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import {
    FilmTestConfig,
    stateCurrentModel,
    stateFilmTestConfig
} from '../../state/handTest/models';
import { updateFilmTestConfig } from '../../state/handTest/actions';
import { checkMinNumber } from '../../utils/misc';
import LoadProfileSelect from '../LoadProfileSelect';
import { InputAdornment } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%'
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54) !important'
    },
    divider: {
        marginBottom: '1rem !important'
    }
}));
interface Props {
    filmTestConfig: FilmTestConfig;
    updateFilmTestConfig: any;
    isSavedTest: boolean;
};

const FormFilmTestConfig: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const targetState: FilmTestConfig = props[stateFilmTestConfig];
    const updateState = props.updateFilmTestConfig;


    return (
        <Box width={1}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="palletsPerDay"
                                    label="Avg Pallets Wrapped Per Day"
                                    type="number"
                                    value={targetState.palletsPerDay}
                                    onKeyDown={e => {
                                        if (['-', '+', 'e'].includes(e.key)) e.preventDefault()
                                    }}
                                    onChange={event => updateState('palletsPerDay', checkMinNumber(event.target.value))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="daysPerWeek"
                                    label="Days Of Operation Per Week"
                                    type="number"
                                    value={targetState.daysPerWeek}
                                    onKeyDown={e => {
                                        if (['-', '+', 'e'].includes(e.key)) e.preventDefault()
                                    }}
                                    onChange={event => updateState('daysPerWeek', checkMinNumber(event.target.value))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="palletHeight"
                                    label="Pallet Height"
                                    type="number"
                                    min='0'
                                    onKeyDown={e => {
                                        if (['-', '+', 'e'].includes(e.key)) e.preventDefault()
                                    }}
                                    value={targetState.palletHeight}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                    }}
                                    onChange={event => updateState('palletHeight', checkMinNumber(event.target.value))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="palletLength"
                                    label="Pallet Length"
                                    type="number"
                                    min='0'
                                    onKeyDown={e => {
                                        if (['-', '+', 'e'].includes(e.key)) e.preventDefault()
                                    }}
                                    value={targetState.palletLength}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                    }}
                                    onChange={event => updateState('palletLength', checkMinNumber(event.target.value))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="palletWidth"
                                    label="Pallet Width"
                                    type="number"
                                    min='0'
                                    onKeyDown={e => {
                                        if (['-', '+', 'e'].includes(e.key)) e.preventDefault()
                                    }}
                                    value={targetState.palletWidth}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                    }}
                                    onChange={event => updateState('palletWidth', checkMinNumber(event.target.value))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <LoadProfileSelect
                                    value={targetState.loadProfile?.id}
                                    onChange={event => updateState('loadProfile', event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="weightRange"
                                    label="Weight Of Load Wrapped"
                                    type="number"
                                    onKeyDown={e => {
                                        if (['-', '+', 'e'].includes(e.key)) e.preventDefault()
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                                    }}
                                    value={targetState.weightRange}
                                    onChange={event => updateState('weightRange', checkMinNumber(event.target.value))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

const mapStateToProps = (state, ownProps?) => ({
    [stateFilmTestConfig]: state?.model?.handTest?.[stateCurrentModel]?.[stateFilmTestConfig] || {}
});

export default connect(
    mapStateToProps,
    {
        updateFilmTestConfig,
    }
)(FormFilmTestConfig);