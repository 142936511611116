/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import TitleH4 from '../material/TitleH4';
import SubTitle from '../material/SubTitle';
import Button from '../material/Button';
import PageContainer from './PageContainer';
import Loading from '../comps/Loading';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import GeneralInfoTab from '../comps/GeneralInfoTab';
import FilmInfoTab from '../comps/FilmInfoTab';
import qs from 'query-string';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
    connect,
    withFormOptionsState,
    withAll,
    withCatAnalysesState,
    withCatAnalysesDispatch,
    withSessionDispatch,
    useValidateTest,
    useSaveTest,
    useGeneratePdf,
    useCloneTest,
    useAlertWindow,
    AlertData,
    useCurrentTestSummary,
    useGetTest,
} from '../state';
import {
    LoggerFactory,
    sf,
    misc,
} from '../utils';
import _ from 'lodash';
import {
    useLocation, 
    useHistory,
} from 'react-router-dom';
import * as catModel from '../state/catModel';
import {
    Theme,
    useTheme
} from '@material-ui/core/styles';
import {
    ValidateTestResponse,
    DraftTestSummary }
from '../types';
import {
    routeEditMachine,
    routeMachineTests
} from '../utils/const';

const logger = LoggerFactory( 'EditTestPage' );


const EditTestPage:React.FC<any> = (props:any) => {
    const {
        currentCatAnalysis,
        createCatAnalysis,
    } = props;
    const location = useLocation();
    const history = useHistory();
    const theme:Theme = useTheme();
    const { getTest, isLoadingGetTest } = useGetTest();
    const [ tabValue, setTabValue ] = React.useState<number>(0);
    const { validateTest, isLoadingValidateTest } = useValidateTest();
    const { saveTest, isLoadingSaveTest } = useSaveTest();
    const { generatePdf, isLoadingGeneratePdf } = useGeneratePdf();
    const { cloneTest, isLoadingCloneTest } = useCloneTest();
    const { openAlertWindow, openAlertWindowForError } = useAlertWindow();
    const { currentTestSummary, isMyTestSummary, setCurrentTestSummaryById } = useCurrentTestSummary();
    const testId:number = misc.toNumberOrNull( qs.parse( location.search ).id as string );
    const isTestEditable = catModel.isWorkingCatAnalysis( currentCatAnalysis ) 
        || isMyTestSummary;
        
    /**
     * /edit-test?id={testId}      <-- calls getTest(testId)
     * /edit-test                  <-- calls createCatAnalysis if currentCatAnalysis is empty
     */
    React.useEffect(
        () => {
            if ( !!! _.isNil( testId ) ) {
                getTest( testId ) ;
                setCurrentTestSummaryById( testId );
            } 
            else if ( _.isEmpty( currentCatAnalysis ) ) {
                createCatAnalysis() ;
            }
        },
        [ testId ]
    );

    const mapValidationErrorsToAlertData = (res:ValidateTestResponse): AlertData => {
        return {
            title: 'Validation Failed',
            text: _.join( res.errors, "\n" ),
        };
    };

    const onClickValidateTest = (event:any) => {
        event.preventDefault();
        return validateTest( currentCatAnalysis )
            .then( sf.thru_if( (res:ValidateTestResponse) => !!! res.valid )(
                (res:ValidateTestResponse) => { throw mapValidationErrorsToAlertData( res ); }
            ))
            .then( sf.tap( () => openAlertWindow( { title: 'Success', text: 'The form data is valid.' } ) ) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    const onClickSaveTest = (event:any) => {
        event.preventDefault();
        return saveTest( testId, currentCatAnalysis )
            .then( (res:DraftTestSummary) => history.replace( `/${routeEditMachine}?id=${res.draftId}`) )
            .then( sf.tap( () => openAlertWindow( { title: 'Success', text: 'The test has been saved.' } ) ) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    const onClickCloneTest = (event:any) => {
        event.preventDefault();
        return cloneTest( testId )
            .then( () => history.push( `/${routeEditMachine}` ) )
            .then( sf.pause( 10 ) )
            .then( sf.tap( () => openAlertWindow( { title: 'Test Cloned', text: 'You can now make edits and save the copy as a new test.' } ) ) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    const isMsEdge = () => {
        const retMe = (window.navigator.appVersion.indexOf("Edg/") != -1) || (window.navigator.appVersion.indexOf("Edge") != -1);
        logger.log( 'isMsEdge', { navigator: window.navigator, appVersion: window.navigator.appVersion, retMe });
        return retMe;
    };

    const onClickGeneratePdf = (event:any) => {
        event.preventDefault();
        return onClickGeneratePdfEdge(event);
        // return isMsEdge()
        //     ? onClickGeneratePdfEdge(event)
        //     : onClickGeneratePdfDefault(event)
        //     ;
    };

    /**
     * open newWindow
     * saveTest
     *      .catch -> show save errors.
     *             -> close newWindow
     * saveTest
     *      .then -> 
     *          replace url
     *          generate pdf
     *              .catch -> show validation errors
     *                     -> close newWindow
     */
    const onClickGeneratePdfDefault = (event:any) => {
        logger.log('onClickGeneratePdfDefault');
        const newWindow = window.open( '/view-pdf', 'PDF_WINDOW' );
        return Promise.resolve( null )
            .then( 
                () => isTestEditable 
                    ? saveTest( testId, currentCatAnalysis )
                    : Promise.resolve({ draftId: testId })
            )
            .then( sf.tap( (res:DraftTestSummary) => history.replace( `/${routeEditMachine}?id=${res.draftId}`) ))
            .then( (res:DraftTestSummary) => generatePdf( res.draftId, currentCatAnalysis ) )
            .then( (blob:Blob) => window.open( URL.createObjectURL(blob), "PDF_WINDOW" ) )
            .catch( sf.tap_throw( () => newWindow.close() ) )
            .catch( 
                (err) => {
                    if ( _.get( err, 'json.value.valid' ) === false ) {
                        throw mapValidationErrorsToAlertData( err.json.value ); 
                    } else {
                        throw err;
                    }
                }
            )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    /**
     * special handling for MSEdge
     */
    const onClickGeneratePdfEdge = (event:any) => {
        return Promise.resolve( null )
            .then( 
                () => isTestEditable 
                    ? saveTest( testId, currentCatAnalysis )
                    : Promise.resolve({ draftId: testId })
            )
            .then( sf.tap( (res:DraftTestSummary) => history.replace( `/${routeEditMachine}?id=${res.draftId}`) ) )
            .then( (res:DraftTestSummary) => generatePdf( res.draftId, currentCatAnalysis )
            .then( 
                (blob:Blob) => {
                    const fileName = `Test${ res.draftId }.pdf`;
                    if (window.navigator.msSaveOrOpenBlob) {
                        logger.log( 'onClickGeneratePdfEdge: msSaveOrOpenBlob');
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    }
                    else if (window.navigator.msSaveBlob) {
                        logger.log( 'onClickGeneratePdfEdge: msSaveBlob');
                        window.navigator.msSaveBlob(blob, fileName);
                    } 
                    else {
                        const link = window.document.createElement("a");
                        if (link.download !== undefined) {
                            link.setAttribute("href", URL.createObjectURL(blob));
                            link.setAttribute("download", fileName);
                            link.style.visibility = 'hidden';
                            window.document.body.appendChild(link);
                            link.click();
                            window.document.body.removeChild(link);
                        } else {
                            throw new Error('Internal Error: document.createElement(a) returned undefined')
                        }
                    }
                } 
            )
            .then( () => { getTest( res.draftId ) } )
            .catch( 
                (err) => {
                    if ( _.get( err, 'json.value.valid' ) === false ) {
                        throw mapValidationErrorsToAlertData( err.json.value ); 
                    } else {
                        throw err;
                    }
                }
            )
            .catch( sf.tap( openAlertWindowForError ) ) );
    };

    const onClickCancel = () => {
        props.history.replace(`${routeMachineTests}`);
    };

    if ( _.isEmpty( currentCatAnalysis ) || isLoadingGetTest ) {
        return (
            <PageContainer isLoading={true}>
                <Loading mt={8} isLoading={true} />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <TitleH4>
                Client Analysis 
            </TitleH4>
            {
                !!! isTestEditable && 
                <Box mt={1}>
                <SubTitle color={'error'}>
                    { '* READ-ONLY: you can only make changes to tests you created'}
                </SubTitle>
                </Box>
            }
            <Box mt={3} border={1} borderColor={theme.palette.text.primary}>
                <GeneralInfoTab />
                <FilmInfoTab filmInfoMap={currentCatAnalysis.filmInfoMap} />
            </Box>
 
            <Box mt={3} mb={5}>
            <Grid container direction={'row-reverse'} spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Button
                        onClick={ onClickGeneratePdf }
                        isLoading={ isLoadingGeneratePdf }
                        fullWidth
                        startIcon={<ArrowDownwardSharpIcon style={{ fontSize: 18 }}/>}
                        // disabled={ _.isNil( testId ) }
                        >
                        PDF
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        onClick={ onClickValidateTest }
                        isLoading={ isLoadingValidateTest }
                        fullWidth
                        >
                        Validate 
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        onClick={ onClickSaveTest }
                        isLoading={ isLoadingSaveTest }
                        disabled={ !!! isTestEditable }
                        fullWidth
                        >
                        Save 
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {
                        isTestEditable &&
                        <Button
                            onClick={ onClickCancel }
                            fullWidth
                            color={'secondary'}
                            >
                            Cancel 
                        </Button>
                    }
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}>
                    {
                        !!! catModel.isWorkingCatAnalysis( currentCatAnalysis ) &&
                        <Button
                            onClick={ onClickCloneTest }
                            isLoading={ isLoadingCloneTest }
                            fullWidth
                            >
                            Clone 
                        </Button>
                    }
                </Grid>
            </Grid>
           </Box>
        </PageContainer>
    );
};

export default connect(
    withAll( withCatAnalysesState, withFormOptionsState ),
    withAll( withCatAnalysesDispatch, withSessionDispatch ),
)( EditTestPage );
