import * as actionTypes from '../actions/types';

import {
    handTestInitialState,
    stateCustomerInfo,
    stateGeneralInfo,
    stateFilmTestConfig,
    stateLoadingForm,
    stateLoadingSummary,
    stateLoadingGetForm,
    stateCurrentModel,
    stateSummariesPagination,
    stateSummaries,
    stateSummariesSearchText,
    stateLoadingGeneratePdf,
    stateCurrentTestSummaryId,
    stateProducts,
    modelInitialState,
    stateLoadingClone
} from '../models/state';

const setHandTestSummariesPagination = (state:any) => (payload:any) => ({
    ...state, 
    [stateSummariesPagination]: {
        ...state[stateSummariesPagination],
        ...payload
    }
});

const setStatePayload = (state:any) => (stateTarget:string) => (payload:any) => ({
    ...state,
    [stateTarget]: payload,
});

const updateCurrentModel = (state:any) => (targetState:string) => (action) => ({
    ...state,
    [stateCurrentModel]: {
        ...state[stateCurrentModel],
        [targetState]: {
            ...state[stateCurrentModel]?.[targetState],
            [action.key]: action.payload
        }
    }
});

const changeLoadingState = (state:any) => (targetState:string) => (payload:boolean) => ({
    ...state,
    isLoading: {
        ...state.isLoading,
        [targetState]: payload
    }
});

const changeDefaultState = (state:any) => (targetState:string) => (payload:any) => ({
    ...state,
    [targetState]: payload
});

const clearCurrentTest = (state:any) => ({
    ...state,
    ...modelInitialState,
})

const handTestReducer = (state = handTestInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_HAND_TEST_SUMMARIES:
            return setStatePayload(state)(stateSummaries)(action.payload)
        case actionTypes.SET_HAND_TEST_SUMMARIES_PAGINATION:
            return setHandTestSummariesPagination(state)(action.payload)
        case actionTypes.SET_HAND_TEST_SUMMARIES_SEARCH_TEXT:
            return setStatePayload(state)(stateSummariesSearchText)(action.payload)
        case actionTypes.SET_HAND_TEST:
            return setStatePayload(state)(stateCurrentModel)(action.payload)
        case actionTypes.UPDATE_GENERAL_INFO:
            return updateCurrentModel(state)(stateGeneralInfo)(action);
        case actionTypes.UPDATE_CUSTOMER_INFO:
            return updateCurrentModel(state)(stateCustomerInfo)(action);
        case actionTypes.UPDATE_FILM_TEST_CONFIG:
            return updateCurrentModel(state)(stateFilmTestConfig)(action);
        case actionTypes.CHANGE_FORM_LOADING_STATE:
            return changeLoadingState(state)(stateLoadingForm)(action.payload);
        case actionTypes.CHANGE_SUMMARY_LOADING_STATE:
            return changeLoadingState(state)(stateLoadingSummary)(action.payload);
        case actionTypes.CHANGE_GET_FORM_LOADING_STATE:
            return changeLoadingState(state)(stateLoadingGetForm)(action.payload);
        case actionTypes.CHANGE_GET_PDF_LOADING_STATE:
            return changeLoadingState(state)(stateLoadingGeneratePdf)(action.payload);
        case actionTypes.CHANGE_CLONE_TEST_LOADING_STATE:
            return changeLoadingState(state)(stateLoadingClone)(action.payload);
        case actionTypes.REMOVE_CURRENT_HAND_FILM_TEST:
            return clearCurrentTest(state);
        case actionTypes.SET_CURRENT_HAND_TEST_BY_ID:
            return changeDefaultState(state)(stateCurrentTestSummaryId)(action.payload)
        case actionTypes.SET_PRODUCTS:
            return changeDefaultState(state)(stateProducts)(action.payload)
        default:
            return state;
    }
};

export default handTestReducer;
