/**
 * @rob4lderman
 * feb2020
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextButton from '../material/TextButton';
import { useAlertWindow } from '../state';
import { config } from '../utils';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0.5),
        minWidth: 0,
    },
    label: {
        textTransform: 'none',
    },
}));
 

const Copyright:React.FC = () => {
    const { openAlertWindow } = useAlertWindow();
    const classes = useStyles();
    const onClickHelp = () => {
        openAlertWindow({
            title: 'Help',
            text: `For all issues with the app, contact Kenny by email at ${config.SUPPORT_EMAIL}`,
        })
    }
    return (
        <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
            <Typography variant="body2" align="center">
                <Link color="inherit" href="http://paragonfilms.com/">
                    Paragon Films,
                </Link>
                {/*' Copyright © '*/}
                {' Copyright '}
                {new Date().getFullYear()}
                {'. All Rights Reserved.'}
            </Typography>
            <TextButton color="inherit" classes={classes} onClick={ onClickHelp } >
                { 'Help?' }
            </TextButton>
        </Box>

    );
}

export default Copyright;