/**
 * @rob4lderman
 * feb2020
 */
import React from 'react';
import MTextField from '@material-ui/core/TextField';

interface Props {
    [key:string]: any;
    name: string;
};

const TextField: React.FC<Props> = (props:Props) => {
    const {
        value,
        ...rprops
    } = props;
    const safeValue = (value === null || value === undefined ) 
        ? ''
        : value
        ;
    return (
        <MTextField
            variant="outlined"
            fullWidth
            onFocus={ (event:any) => event.target.select() }
            id={props.name}
            value={ safeValue }
            {...rprops}
        />
    );
};
 
export default TextField;