/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    LoggerFactory,
    misc,
    sf,
} from '../utils';
import _ from 'lodash';
import { 
    usePalletInfo,
} from '../state';
import { InputAdornment } from '@material-ui/core';
import LoadProfileSelect from './LoadProfileSelect';
const logger = LoggerFactory( 'PalletInfoForm' );

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        // -rx- marginTop: theme.spacing(3),
    },
}));

interface Props {};

 
const PalletInfoForm:React.FC<Props> = (props:Props) => {
    const [ _palletInfo, setPalletInfo ] = usePalletInfo();
    const classes = useStyles();
    logger.log( 'render', { props });

    return (
        <Box style={{width:'100%'}}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    {/* first column */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="palletsPerDay"
                                    type="number"
                                    label="Avg Pallets Wrapped Per Day"
                                    value={ misc.numberToString( _palletInfo.palletsPerDay ) }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'palletsPerDay' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    label="Days Of Operation Per Week"
                                    name="daysPerWeek"
                                    value={ misc.numberToString( _palletInfo.daysPerWeek ) }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'daysPerWeek' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* second column */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    name="palletHeight"
                                    label="Pallet Height"
                                    value={ misc.numberToString( _palletInfo.palletHeight ) }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">in</InputAdornment>,
                                    }}
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'palletHeight' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    name="palletLength"
                                    label="Pallet Length"
                                    value={ misc.numberToString( _palletInfo.palletLength ) }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">in</InputAdornment>,
                                    }}
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'palletLength' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    name="palletWidth"
                                    label="Pallet Width"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">in</InputAdornment>,
                                    }}
                                    value={ misc.numberToString( _palletInfo.palletWidth ) }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'palletWidth' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* third column */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <LoadProfileSelect
                                    value={ _palletInfo.loadProfile.id }
                                    onChange={ 
                                        _.flow(
                                            sf.lens( 'loadProfile' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    name="weightRange"
                                    label="Weight Of Load Wrapped"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">lbs</InputAdornment>,
                                    }}
                                    value={ misc.numberToString( _palletInfo.weightRange ) }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'weightRange' ).extend( _palletInfo ),
                                            sf.tap( setPalletInfo ),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default PalletInfoForm;