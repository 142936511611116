/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    LoggerFactory,
} from '../utils';
import _ from 'lodash';
import { 
    EquipmentType,
} from '../types';
const logger = LoggerFactory( 'EquipmentTypeAutoComplete' );

interface Props {
    equipmentTypes: EquipmentType[];
    value: any;
    onChange: (event:any) => any;
};

const EquipmentTypeAutoComplete:React.FC<Props> = (props:Props) => {
    const {
        equipmentTypes,
        value,
        onChange,
    } = props;
    logger.log( 'render', { props });
    return (
        <Autocomplete
            id="equipmentType"
            freeSolo
            selectOnFocus
            forcePopupIcon={true}
            options={equipmentTypes}
            getOptionLabel={option => option.name}
            style={{ width: '100%'}}
            onInputChange={
                (event:any, value:string, reason:string) => {
                    onChange( { target: { value } } )
                }
            }
            renderInput={
                (params) => {
                    return (
                        <TextField 
                            {..._.merge( params, { inputProps: { value } } ) }
                            name="equipmentType"
                            label="EquipmentType" 
                            variant="outlined" 
                            fullWidth 
                            value={ value }
                            onBlur={ onChange }
                        />
                    )
                }
            }
        />
    )
};

export default EquipmentTypeAutoComplete;