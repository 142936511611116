import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    LoggerFactory,
    sf,
} from '../utils';
import _ from 'lodash';
import { 
    useGeneralInfo,
} from '../state';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import {
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
const logger = LoggerFactory( 'GeneralInfoForm' );

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
    },
}));

interface Props {} ;

const GeneralInfoForm:React.FC<Props> = (props:Props) => {
    const [ _generalInfo, setGeneralInfo ] = useGeneralInfo();

    const classes = useStyles();
    logger.log( 'render', { _generalInfo, props });

    return (
        <Box style={{width:'100%'}}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="name"
                            label="Test Name"
                            value={ _generalInfo.name }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'name' ).extend( _generalInfo ),
                                    sf.tap( setGeneralInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/DD/YYYY"
                            fullWidth
                            id="testDateTime"
                            label="Test Date"
                            value={ _generalInfo.testDateTime }
                            onChange={
                                _.flow(
                                    (date:moment.Moment|null) => date.toDate(),
                                    sf.lens( 'testDateTime' ).extend( _generalInfo ),
                                    sf.tap( setGeneralInfo ),
                                )
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            autoOk={true}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            multiline
                            rows={ Math.max( 2, _.defaultTo( _.split( _generalInfo.notes, "\n" ), []).length ) }
                            name="notes"
                            label="Notes (1000 characters maximum)"
                            inputProps={{
                                maxLength: 1000,
                            }}
                            value={ _generalInfo.notes }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'notes' ).extend( _generalInfo ),
                                    sf.tap( setGeneralInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ _generalInfo.includeNotesOnReport }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.checked' ).get,
                                            sf.lens( 'includeNotesOnReport' ).extend( _generalInfo ),
                                            sf.tap( setGeneralInfo ),
                                        )
                                    }
                                    color="primary"
                                />
                            }
                            label="Check this box to include Notes on the report."
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default GeneralInfoForm;