/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import MExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// TODO: import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'uppercase',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    }),
);

interface Props {
    children: any;
    title: string;
    [key:string]: any;
};

const ExpansionPanel:React.FC<Props> = (props:Props) => {
    const {
        children,
        title,
        ...rprops
    } = props;
    const classes = useStyles();
    return (
        <MExpansionPanel {...rprops}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    { title }
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                { children }
            </ExpansionPanelDetails>
        </MExpansionPanel>
    );
};

export default ExpansionPanel;