/**
 * @rob4lderman
 * feb2020
 *
 */
import React from 'react';
import {
    useState,
} from 'react';
import Button from '../material/Button';
import TextField from '../material/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    LoggerFactory,
    misc,
} from '../utils';
import _ from 'lodash';
const logger = LoggerFactory('ResetPasswordForm');

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

interface Props {
    onSubmit: (event:Event, newPassword:string) => Promise<any>;
    isLoading?: boolean;
};

const ResetPasswordForm: React.FC<Props> = (props:Props) => {
    const {
        onSubmit,
        isLoading = false,
    } = props;
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState( '' );
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ confirmPasswordError, setConfirmPasswordError ] = useState( '' );
    const classes = useStyles();

    logger.log( 'render', { props });

    const validate = ( data:any ): string => {
        logger.log( "ResetPasswordForm.validate", { password } );
        const validated = [
            misc.validateNotEmpty( data.password, _.partial( setPasswordError, 'Required' ) ),
            misc.validateNotEmpty( data.confirmPassword, _.partial( setConfirmPasswordError, 'Required' ) ),
            misc.validateTrue( data.confirmPassword === data.password, _.partial( setConfirmPasswordError, 'Password does not match' ) ),
        ];
        misc.assertAllTrue( validated )
        return password;
    };

    const _onSubmit = (event:any) => {
        event.preventDefault();
        return Promise.resolve( { password, confirmPassword } )
            .then( validate )
            .then( _.partial( onSubmit, event ) )
            .catch( logger.logErrorFn('onSubmit') )
            ;
    };

    return (
        <form className={classes.form} noValidate>
             <TextField
                 margin="normal"
                 // required
                 fullWidth
                 name="password"
                 label="Please choose a new password"
                 type="password"
                 autoComplete="current-password"
                 value={password}
                 onChange={ misc.onChangeTextField( setPassword, setPasswordError )}
                 {...misc.getTextFieldError( passwordError )}
             />
             <TextField
                 margin="normal"
                 // required
                 fullWidth
                 name="confirmPassword"
                 label="Confirm password"
                 type="password"
                 autoComplete="off"
                 {...misc.getTextFieldError( confirmPasswordError )}
                 value={confirmPassword}
                 onChange={ misc.onChangeTextField( setConfirmPassword, setConfirmPasswordError ) }
             />
             <Box mt={3}></Box>
             <Button
                 type="submit"
                 fullWidth
                 onClick={ _onSubmit }
                 isLoading={ isLoading }
                 >
                 Change Password
             </Button>
        </form>
    );
};


export default ResetPasswordForm;
