import React, { useEffect } from 'react';
import {
    routeEditHand,
    sf,
} from '../utils';
import {
    connect,
    withAll,
    withCatAnalysesDispatch,
    withSessionState,
    useUserProfile,
    useFilterMyTests,
    useTestSummariesPagination,
    useTestSummariesSorting,
    useAlertWindow,
} from '../state';
import * as catModel from '../state/catModel';
import Button from '../material/Button';
import TitleH4 from '../material/TitleH4';
import Hidden from '@material-ui/core/Hidden';
import TablePagination from '@material-ui/core/TablePagination';
import SearchTextField from '../material/SearchTextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import TestSummaryTable from '../comps/TestSummaryTable';
import TestSummaryTableXs from '../comps/TestSummaryTableXs';
import { TestSummary } from '../types';
import PageContainer from './PageContainer';
import {
    useHistory,
} from 'react-router-dom';
import AccountPendingAlert from '../comps/AccountPendingAlert';
import {
    useSearchText
} from '../state/handTest/hooks';
import { withHandTestFormState, withHandTestSelectors } from '../state/handTest/selectors';

const SavedHandTestsPage: React.FC<any> = (props:any) => {
    const {
        testSummaries,
        hasRole,
        getHandTestSummaries,
        isLoadingTestSummaries,
        createCatAnalysis,
        removeHandFilm
    } = props;
    const [ searchText, setSearchText ] = useSearchText();
    const [ filterMyTests, setFilterMyTests] = useFilterMyTests();
    const [ userProfile ] = useUserProfile();
    const history = useHistory();
    const rowsPerPageOptions= [10, 50, 100];
    const [ testSummariesPagination, setTestSummariesPagination ] = useTestSummariesPagination();
    const [ testSummariesSorting, setTestSummariesSorting ] = useTestSummariesSorting();
    const { openAlertWindow, openAlertWindowForError } = useAlertWindow();

    const onClickCreateCatAnalysis = (event:any) => {
        if (!!! hasRole) {
            openAlertWindow( { 
                title: 'Account Pending', 
                text: 'Your profile information is being verified. You will receive an email when you are able to access full functionality.' 
            } )
            return ;
        }
        removeHandFilm();
        createCatAnalysis();
        history.push( `/${routeEditHand}` );
    };
      
    const onClickTestSummary = (event:any, testSummary:TestSummary) => {
        history.push(`/${routeEditHand}?id=${testSummary.id}`) 
    };

    const onClickSort = (sortFieldName:string) => {
        const isSameField = testSummariesSorting.sortFieldName === sortFieldName ;
        const isDesc = testSummariesSorting.sortDirection === 'desc';
        setTestSummariesSorting({
            sortFieldName: isSameField && isDesc ? null : sortFieldName,    // reset
            sortDirection: isSameField && !!! isDesc ? 'desc' : 'asc'
        });
    };

    const onChangeRowsPerPage = _.flow( 
        sf.lens( 'target.value' ).get,
        _.toNumber,
        (rowsPerPage:number) => ({ page: 0, rowsPerPage }),
        setTestSummariesPagination,
    );

    const resetPageZero = () => {
        setTestSummariesPagination( sf.lens('page').set(0)( testSummariesPagination ));
    };

    const isMyTestSummary = (testSummary:TestSummary): boolean => {
        return catModel.isTestSummaryCreatedBy( testSummary, userProfile.email );
    };

    const sortTestSummaries = (ts:TestSummary[]) => {
        if ( _.isEmpty( testSummariesSorting.sortFieldName )) {
            return ts;
        }
        return _.orderBy( ts, _.flow( sf.lens( testSummariesSorting.sortFieldName ).get, _.toLower ), [ testSummariesSorting.sortDirection ]);
    };

    const filteredTestSummaries = _.chain( testSummaries )
        .filter( filterMyTests ? isMyTestSummary : () => true )
        .filter( _.partial( catModel.matchTestSummary, searchText ) ) 
        .thru( sortTestSummaries )
        .value();

    const pagedTestSummaries = _.chain(filteredTestSummaries)
        .slice( 
            testSummariesPagination.page * testSummariesPagination.rowsPerPage, 
            testSummariesPagination.page * testSummariesPagination.rowsPerPage + testSummariesPagination.rowsPerPage 
        )
        .value();

    const renderTestPagination = () => {
        return (
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                labelRowsPerPage={'Rows'}
                component="div"
                count={filteredTestSummaries.length}
                rowsPerPage={testSummariesPagination.rowsPerPage}
                page={testSummariesPagination.page}
                onChangePage={ 
                    _.flow(
                        _.nthArg(1),
                        sf.lens('page').extend( testSummariesPagination ),
                        setTestSummariesPagination,
                    )
                }
                onChangeRowsPerPage={ onChangeRowsPerPage }
            />
        );
    };

    return (
        <PageContainer>
            <Box mb={5}>
                {
                    !hasRole &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AccountPendingAlert>
                                Account Pending: Your profile information is being verified. 
                                You will receive an email when you are able to access full functionality.
                            </AccountPendingAlert>
                        </Grid>
                    </Grid>
                }
 
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <TitleH4>
                           Hand Tests
                        </TitleH4>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            onClick={ onClickCreateCatAnalysis }
                            fullWidth
                            >
                            NEW TEST
                        </Button>
                    </Grid>
                </Grid>
 
                <Box mt={2}>
                    <Grid container alignItems={'center'} spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterMyTests}
                                        onChange={ 
                                            () => {
                                                setFilterMyTests( !!! filterMyTests );
                                                resetPageZero();
                                            }
                                        }
                                        value="filterMyTestsOnly"
                                        color="primary"
                                    />
                                }
                                label="Your Tests"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SearchTextField 
                                value={searchText}
                                onChange={
                                    _.flow( 
                                        sf.lens( 'target.value' ).get,
                                        setSearchText,
                                        resetPageZero,
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            { renderTestPagination() }
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={1}></Box>
                <Hidden xsDown>
                    <TestSummaryTable 
                        testSummaries={ pagedTestSummaries }
                        isLoadingTestSummaries={isLoadingTestSummaries}
                        onClickTestSummary={ onClickTestSummary }
                        onClickSort={ onClickSort }
                        sortDirection={ testSummariesSorting.sortDirection }
                        sortFieldName={ testSummariesSorting.sortFieldName }
                    />
                </Hidden>
                <Hidden smUp>
                    <TestSummaryTableXs 
                        testSummaries={ pagedTestSummaries }
                        isLoadingTestSummaries={isLoadingTestSummaries}
                        onClickTestSummary={ onClickTestSummary }
                    />
                </Hidden>
                {
                    !!! isLoadingTestSummaries &&
                    filteredTestSummaries.length > 0 &&
                    renderTestPagination() 
                }
            </Box>
        </PageContainer>
    );
};


export default connect(
    withAll( withHandTestFormState, withSessionState ),
    withAll( withCatAnalysesDispatch, withHandTestSelectors ),
)( SavedHandTestsPage );
