/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';import _ from 'lodash';
import { 
    Manufacturer 
} from '../types';
interface Props {
    manufacturers: Manufacturer[];
    value: any;
    onChange: (event:any) => any;
    [key:string]: any;
};


const ManufacturerAutoComplete:React.FC<Props> = (props:Props) => {
    const {
        manufacturers,
        value,
        onChange,
        ...rprops
    } = props;

    const options = manufacturers;

    return (
        <Autocomplete
            id="manufacturer"
            selectOnFocus
            forcePopupIcon={true}
            options={options}
            getOptionLabel={option => option.name }
            style={{ width: '100%' }}
            onInputChange={
                (event:any, value:string, reason:string) => {
                    onChange( { target: { value } } )
                }
            }
            renderInput={
                (params) => {
                    return (
                        <TextField 
                            {..._.merge( params, { inputProps: { value } } ) }
                            name="manufacturer"
                            label="Manufacturer" 
                            variant="outlined" 
                            fullWidth 
                            value={ value }
                            onBlur={ onChange }
                        />
                    )
                }
            }
            {...rprops}
        />
    )
};

export default ManufacturerAutoComplete;