/**
 * @rob4lderman
 * feb2020
 *
 */
import React from 'react';
import ResetPasswordForm  from '../comps/ResetPasswordForm';
import {
    LoggerFactory,
    sf,
} from '../utils';
import {
    withSessionState,
    withSessionDispatch,
    connect,
    useAlertWindow,
} from '../state';
import qs from 'query-string';
import _ from 'lodash';
import SignInContainer from '../comps/SignInContainer';
import SignInFormBox from '../comps/SignInFormBox';
const logger = LoggerFactory( 'ResetPasswordPage' );

/**
 *
 */
const ResetPasswordPage: React.FC<any> = (props:any) => {
    const {
        resetPasswordNew,
    } = props;
    const [ isLoading, setIsLoading ] = React.useState(false);
    const { openAlertWindowForError } = useAlertWindow();
    logger.log( "render", { props, qs: qs.parse( props.location.search ) } );

    const onSubmit = (event:any, newPassword:string ): Promise<any> => {
        event.preventDefault();
        const token = qs.parse( props.location.search ).token;
        setIsLoading(true);
        return resetPasswordNew( { newPassword, token }) 
            .then( sf.tap( _.partial( setIsLoading, false ) ))
            .then( () => props.history.push(`/machine-tests`) )
            .catch( sf.tap_throw( _.partial( setIsLoading, false ) ))
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    return (
        <SignInContainer>
            <SignInFormBox
                title={"RESET PASSWORD"}
                >
                <ResetPasswordForm
                    onSubmit={ onSubmit }
                    isLoading={ isLoading }
                />
            </SignInFormBox>
        </SignInContainer>
    );
};


export default connect(
    withSessionState,
    withSessionDispatch
)( ResetPasswordPage );
