/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const SearchTextField:React.FC<any> = (props:any) => {
    const classes = useStyles();
    return (
      <Paper component="div" className={classes.root}>
          <InputBase
              className={classes.input}
              placeholder="Search"
              onFocus={ (event:any) => event.target.select() }
              inputProps={{ 
                  'aria-label': 'search' 
              }}
              {...props}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
          </IconButton>
      </Paper>
    );
};

export default SearchTextField;