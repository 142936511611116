/**
 * @rob4lderman
 * feb2020
 */


export default {
    // AUTH_URL: 'https://pfcatweb.azurewebsites.net/auth',
    // API_URL: 'https://pfcatweb.azurewebsites.net/api/Cat',
    AUTH_URL: process.env.REACT_APP_AUTH_URL,
    API_URL: process.env.REACT_APP_API_URL,
    RESET_PASSWORD_URL: process.env.REACT_APP_RESET_PASSWORD_URL,   // 'http://localhost:3000/reset-password',
    LOGIN_PATHNAME: '/signin',
    SUPPORT_EMAIL: 'Kenny.Mullican@paragonfilms.com',
};