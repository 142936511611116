/**
 * @rob4lderman
 * feb2020
 * 
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { 
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import {
    LoggerFactory,
} from '../utils';
import {
    withFormOptionsState,
    useTestMethod,
    connect,
} from '../state';
import TestMethodSelect from './TestMethodSelect';
import { TestMethod } from '../types';
const logger = LoggerFactory( 'FilmTestMethodForm' );

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        // -rx- marginTop: theme.spacing(3),
    },
}));

interface Props {
    testMethods: TestMethod[];
};

const FilmTestMethodForm:React.FC<Props> = (props:Props) => {
    const {
        testMethods,
    } = props;
    const [ testMethod, setTestMethod ] = useTestMethod();
    const classes = useStyles();

    return (
        <Box style={{width:'100%'}}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TestMethodSelect
                            value={ testMethod.id }
                            testMethods={testMethods}
                            onChange={ setTestMethod }
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};


export default connect(
    withFormOptionsState,
)( FilmTestMethodForm );