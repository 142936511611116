/**
 * @rob4lderman
 * mar2020
 */


import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DrawerMenu from '../comps/DrawerMenu';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import {
    useHistory,
} from 'react-router-dom';
import {
    connect,
    withAll,
    withCatAnalysesDispatch,
    withSessionDispatch,
    withUserState,
    useUserProfile,
    useAlertWindow,
} from '../state';
import {
    sf,
} from '../utils';
import _ from 'lodash';
import Copyright from '../comps/Copyright';

const drawerWidth = 88; // in px

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: '#e5e5e5',
      boxShadow: 'none',
      [theme.breakpoints.up('sm')]: {
        // -rx- width: `calc(100% - ${drawerWidth}px)`,
        // -ry- width: '100%',
        zIndex: 1210,   // zIndex of drawer is 1200
        // marginLeft: drawerWidth,
        // paddingLeft: drawerWidth,
      },
    },
    // invisible on screens above sm breakpoint.
    menuButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // this is a helper style for adjusting the page content
    // to accommodate the fixed-position AppBar.
    accommodateAppBar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      paddingLeft: 0,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
    logoBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: drawerWidth,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
  }),
);

const useCopyrightStyles = makeStyles(theme => ({
    containerRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    copyrightBox: {
        // position: 'fixed',
        // bottom: 0,
        // backgroundColor: theme.palette.text.primary,
        marginTop: theme.spacing(16),
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        width: '100%',
        padding: theme.spacing(2),
    },
    backgroundBlack: {
        position: 'fixed',
        bottom: 0,
        height: '50%',
        width: '100%',
        backgroundColor: theme.palette.text.primary,
        zIndex: -10000,
    },
}));

interface Props {
    // TODO
    children?: any;
    [key:string]: any;
}

const PageContainer:React.FC<Props> = (props: Props) => {
    const { 
        children,
        logout,
        createCatAnalysis,
        isLoading = false,
    } = props;
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
    const history = useHistory();
    const { openAlertWindowForError } = useAlertWindow();
    const copyrightClasses = useCopyrightStyles();

    const onClickLogOut = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<any> => {
        return logout()
            .then( () => history.push(`/signin`) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    const onClickNewTest = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        return createCatAnalysis()
            .then( () => history.push( '/edit-test' ) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    const onClickSavedTests = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        history.push( '/machine-tests' );
    };

    const onClickSavedHandTests = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        history.push( '/hand-tests' );
    };

    const onClickUserProfile = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        history.push( '/user-profile' );
    };

    const onClickChangePassword = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        history.push( '/change-password' );
    };

    return (
        <Container className={classes.root}>
            <CssBaseline />
            <PageAppBar 
                onClickDrawer={() => setIsDrawerOpen( (val:boolean) => !!! val )}
            />
            <DrawerNav
                onCloseDrawer={() => setIsDrawerOpen( (val:boolean) => !!! val )}
                isDrawerOpen={isDrawerOpen}
                >
                <DrawerMenu 
                    onClickNewTest={onClickNewTest}
                    onClickSavedTests={onClickSavedTests}
                    onClickSavedHandTests={onClickSavedHandTests}
                    onClickUserProfile={onClickUserProfile}
                    onClickChangePassword={onClickChangePassword}
                    onClickLogOut={onClickLogOut}
                />
            </DrawerNav>
            <main className={classes.content}>
                {/* this is here to accommodate the fixed-position toolbar */}
                <div className={classes.accommodateAppBar} />
                { children }
                {
                    !!! isLoading &&
                    <Box className={copyrightClasses.copyrightBox}>
                        <Copyright />
                    </Box>
                }
           </main>
        </Container>
    );
};


const PageAppBar:React.FC<any> = (props:any) => {
    const {
        onClickDrawer,
    } = props;
    const classes = useStyles();
    const [ userProfile ] = useUserProfile();
    const iconWidth = 45;
    const iconHxWRatio = 145/120;
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar disableGutters={true}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onClickDrawer}
                    className={classes.menuButton}
                    >
                    <MenuIcon />
                </IconButton>
                <Box className={classes.logoBox} >
                    <Link href="/">
                        <img 
                            src="/paragon.logo.small.centered.png" 
                            alt="paragon films"
                            width={iconWidth}
                            height={`${iconWidth * iconHxWRatio}`}
                            style={{ marginTop: 5 }} // for centering veritically. the <a> in the Link has a mysterious 5px bottom padding 
                        />
                    </Link>
                </Box>
                <Box ml={3}>
                    <Typography variant="h6" noWrap>
                        { _.get( userProfile, 'firstName' ) } { _.get( userProfile, 'lastName' ) }
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
};


const DrawerNav:React.FC<any> = (props:any) => {
    const {
        onCloseDrawer,
        isDrawerOpen,
        children,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={isDrawerOpen}
                    onClose={onCloseDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    >
                    {children}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                    >
                    {children}
                </Drawer>
            </Hidden>
        </nav>
    );
};


export default connect(
    withAll( withUserState ),
    withAll( withSessionDispatch, withCatAnalysesDispatch )
)( PageContainer );