import React from 'react';
import ExpansionPanel from '../material/ExpansionPanel';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import FilmTestMethodForm from './FilmTestMethodForm';
import FilmProductInfoFormList from './FilmProductInfoFormList';
import FilmConfigurationFormList from './FilmConfigurationFormList';
import FilmTestResultsFormList from './FilmTestResultsFormList';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    backgroundGrey: {
        backgroundColor: '#e5e5e5',
    },
}));

const useExpansionPanelStyles = makeStyles((theme: Theme) => ({
    root: {
        '&$expanded': {
            margin: 0,
        }
    },
    expanded: {
        margin: 0,
    },
}));

const FilmInfoTab:React.FC<any> = (props:any) => {
    const {
        filmInfoMap,
        handType
    } = props;

    const classes = useStyles();
    const expansionPanelClasses = useExpansionPanelStyles();

    if ( _.isEmpty( filmInfoMap )) {
        return null;
    }
    
    return (
        <Box>
            <ExpansionPanel 
                title="Film Comparison: Product" 
                defaultExpanded={false}     
                classes={expansionPanelClasses}
                className={ (handType && classes.backgroundGrey) }
                >
                <FilmProductInfoFormList filmInfoMap={filmInfoMap} />
            </ExpansionPanel>
            <ExpansionPanel 
                title="Film Comparison: Configuration" 
                classes={expansionPanelClasses}
                className={ (!handType && classes.backgroundGrey) }
                >
                <FilmConfigurationFormList filmInfoMap={filmInfoMap} />
            </ExpansionPanel>
            <ExpansionPanel 
                title="Film Comparison: Test Results" 
                classes={expansionPanelClasses}
                className={ (handType && classes.backgroundGrey) }
                >
                <Box>
                    <FilmTestMethodForm />
                    <Box mt={2}>
                        <FilmTestResultsFormList filmInfoMap={filmInfoMap} />
                    </Box>
                    <Box mt={3}>
                        <Typography 
                            component="h6"
                            variant="body1"
                            >
                                Containment fields are in pounds with two decimal places. Example: 8 pounds, 12 ounces would be entered as 8.75.
                        </Typography>
                    </Box>
                </Box>
            </ExpansionPanel>
        </Box>
    );
};

export default FilmInfoTab;
