/**
 * @rob4lderman
 * feb2020
 * 
 */

import _ from 'lodash';

const LOGGERS_ENABLED = [
    // 'SignInPage',
    // 'SignInForm',
    // 'fetch',
    // 'model',
    // 'paragon',
    // 'EditTestPage',
    // 'SavedTestsPage',
    // 'PageContainer',
    // 'HomePage',
    // 'UserProfileForm',
    // 'ChangePasswordForm',
    // 'App',
    // 'AuthRoute',
    // 'ManufacturerAutoComplete',
    // 'GeneralInfoForm',
    // 'CustomerInfoForm',
    // 'ContactForm',
    // 'FilmProductInfoForm',
    // 'ManufacturerAutoComplete',
    // 'ProductAutoComplete',
 ];

const ALL_ENABLED = false;

export const LoggerFactory = (name:string) => {
    const log = (...args) => {
        if ( !!! ALL_ENABLED && !!! _.find( LOGGERS_ENABLED, loggerName => name.startsWith( loggerName ) ) ) {
            return;
        }

        args.unshift(`${(new Date()).toISOString()}: LOG   ${name} `);
        console.log.apply(console, args as any);
        
        return _.last( args );
    };

    const info = (...args) => {
        args = _.map( 
            args, 
            (arg, i) => ( i===0 && _.isString(arg) ) 
                ? `${(new Date()).toISOString()}: INFO  ${name} ${arg}`
                : arg
        );
        console.log.apply(console, args as any);
    };
        
    const error = (...args) => {
        args.unshift(`${(new Date()).toISOString()}: ERROR ${name} `);
        console.error.apply(console, args as any);
    };

    const tapFn = fn => value => {
        fn(value);
        return value;
    };

    const tap = (msg:string, value:any) => {
        log(msg, value);
        return value;
    };

    const traceFn = (msg:string, fn) => {
        return (...args) => {
            info( `traceFn:entry ${msg}` );
            console.time( `TIMING:${msg}` );
            return Promise.resolve( fn( ...args ) )
                .then( tapFn( () => info( `traceFn:exit ${msg}`)))
                .then( tapFn( () => console.timeEnd( `TIMING:${msg}` ) ) )
                ;
        };
    };

    const logErrorFn = (val:any) => (err:any) => {
        error( 'ERROR', { val, err } );
    };

    /**
     * Alert error.
     * @return void
     */
    const alertError = (err) => {
        log( "alertError", { err } );
        return window.alert( `Error: ${ getErrorMessage(err) }` ); 
    };

    /**
     * 
     * @param {} err 
     * @return string error message
     */
    const getErrorMessage = (err) => {
        return _.isString( err ) 
                ? err
                : _.result( err, 'message', "Something bad happened.... Try again, or maybe restart the app." ) 
                ;
    };

    return {
        log,
        info,
        error,
        traceFn,
        tap,
        logErrorFn,
        alertError,
    };
};

