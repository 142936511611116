/**
 * @rob4lderman
 * mar2020
 * 
 * TODO: saved confirmation
 *
 */
import React from 'react';
import {
    LoggerFactory,
    sf,
 } from '../utils';
import { 
    withSessionState,
    withSessionDispatch,
    connect,
    useUpdateUserProfile,
    useChangePassword,
    useAlertWindow,
} from '../state';
import PageContainer from './PageContainer';
import UserProfileForm from '../comps/UserProfileForm';
import ChangePasswordForm from '../comps/ChangePasswordForm';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '../material/Button';
import TitleH4 from '../material/TitleH4';
const logger = LoggerFactory( 'UserProfilePage' );

/**
 *
 */
const UserProfilePage: React.FC<any> = (props:any) => {

    const { updateUserProfile, isLoadingUserProfile } = useUpdateUserProfile();
    const { changePassword, isLoadingChangePassword } = useChangePassword();
    const { openAlertWindow, openAlertWindowForError } = useAlertWindow();

    const onClickSave = (event:any) => {
        return updateUserProfile()
            .then( () => openAlertWindow( { title: 'Success', text: 'Your profile has been saved.' }) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    const onClickChangePassword = (event:any) => {
        return changePassword()
            .then( () => openAlertWindow( { title: 'Success', text: 'Your password has been changed.' }) )
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    logger.log( "render", { props } );

    return (
        <PageContainer>
            <Box>
                <TitleH4>
                   Your Profile
                </TitleH4>
                <UserProfileForm />
                <Box mt={2}></Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            onClick={ onClickSave }
                            isLoading={isLoadingUserProfile}
                            fullWidth
                            >
                            Save 
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={8} mb={5}>
                <TitleH4>
                   Change Password
                </TitleH4>
                <ChangePasswordForm />
                <Box mt={2}></Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            onClick={ onClickChangePassword }
                            isLoading={ isLoadingChangePassword }
                            fullWidth
                            >
                            Change Password
                        </Button>
                    </Grid>
                </Grid>
            </Box>
 
        </PageContainer>
    );
};


export default connect( 
    withSessionState,
    withSessionDispatch
)( UserProfilePage );

