/**
 * @rob4lderman
 * feb2020
 *
 * Copied lovingly from :
 * https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/page-layout-examples/sign-in/SignIn.js
 *
 */
import React from 'react';
import {
    useState,
} from 'react';
import TextField from '../material/TextField';
import Box from '@material-ui/core/Box';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import {
    withUserState,
    connect,
    useSignInEmail,
} from '../state';
import {
    LoggerFactory,
    misc,
    sf,
} from '../utils';
import {
    LoginRequest,
} from '../types';
import _ from 'lodash';
import Button from '../material/Button';
const logger = LoggerFactory('SignInForm');

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));


interface Props {
    onSubmit: (event:Event, formData:LoginRequest) => Promise<any>;
    isLoading: boolean;
};

const SignInForm: React.FC<Props> = (props:Props) => {
    const {
        onSubmit,
        isLoading,
    } = props;
    const [ username, setUsername ] = useSignInEmail();
    const [ password, setPassword ] = useState( '' );
    const [ usernameError, setUsernameError ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const classes = useStyles();

    logger.log( 'render', { username, props });

    const validate = (data:LoginRequest): LoginRequest => {
        logger.log( "SignInForm.validate", { data } );
        const validated = [
            misc.validateNotEmpty( data.username, _.partial( setUsernameError, 'Required' ) ),
            misc.validateNotEmpty( data.password, _.partial( setPasswordError, 'Required' ) ),
        ];
        misc.assertAllTrue( validated )
        return data;
    };

    const _onSubmit = (event:any) => {
        event.preventDefault();
        return Promise.resolve({ 
                username, 
                password,
            })
            .then( validate )
            .then( _.partial( onSubmit, event ) )
            .catch( logger.logErrorFn('onSubmit') )
            ;
    };

    return (
        <form className={classes.form} noValidate>
             <TextField
                 margin="normal"
                 // required
                 fullWidth
                 label="Email"
                 type="email"
                 name="username"
                 value={username}
                 autoComplete="email"
                 {...misc.getTextFieldError( usernameError )}
                 onChange={ 
                     _.flow( 
                         sf.lens( 'target.value' ).get,
                         setUsername,
                         _.partial( setUsernameError, '' )
                     )
                 }
             />
             <TextField
                 margin="normal"
                 // required
                 fullWidth
                 name="password"
                 label="Password"
                 type="password"
                 autoComplete="current-password"
                 value={password}
                 {...misc.getTextFieldError( passwordError )}
                 onChange={ 
                     _.flow( 
                         sf.lens( 'target.value' ).get,
                         setPassword,
                         _.partial( setPasswordError, '' )
                     )
                 }
             />
             {/*
             <FormControlLabel
                 control={<Checkbox value="remember" color="primary" />}
                 label="Remember me"
             />
             */}
             <Box mt={3}></Box>
             <Button
                 fullWidth
                 type="submit"
                 onClick={ _onSubmit }
                 isLoading={isLoading}
                 >
                 Sign In
             </Button>
         </form>
    );
};

export default connect(
    withUserState
)( SignInForm );
