import React, { useEffect } from 'react';
import TitleH4 from '../material/TitleH4';
import SubTitle from '../material/SubTitle';
import Button from '../material/Button';
import PageContainer from './PageContainer';
import Loading from '../comps/Loading';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import TabGeneralInfo from '../comps/handTest/TabGeneralInfo';
import TabFilmInfo from '../comps/FilmInfoTab';
import qs from 'query-string';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
    connect,
    withFormOptionsState,
    withAll,
    withCatAnalysesState,
    withCatAnalysesDispatch,
    withSessionDispatch,
    useValidateHandTest,
    useAlertWindow,
    AlertData,
    useCurrentTestSummary,
} from '../state';

import { withHandTestFormState, withHandTestSelectors } from '../state/handTest/selectors';
import {
    sf,
    misc,
} from '../utils';
import _ from 'lodash';
import {
    useLocation, 
    useHistory,
} from 'react-router-dom';
import * as catModel from '../state/catModel';
import {
    Theme,
    useTheme
} from '@material-ui/core/styles';
import {
    ValidateTestResponse,
    DraftTestSummary
} from '../types';
import {
    routeHandTests,
    routeEditHand
} from '../utils/const';
import {
    useGetHandTest,
    useSaveHandTest,
    useGeneratePdf,
    useSetCurrentHandTestSummary,
    useCloneHandTest
} from '../state/handTest/hooks';

const EditHandTestPage:React.FC<any> = (props:any) => {
    const {
        currentCatAnalysis,
        createCatAnalysis,
        currentHandTestAnalysys
    } = props;

    const location = useLocation();
    const history = useHistory();
    const theme:Theme = useTheme();
    const { getHandTest, isLoadingGetState } = useGetHandTest();
    const { validateHandTest, isLoadingValidateTest } = useValidateHandTest();
    const { saveHandTest, isLoadingSaveTest } = useSaveHandTest();
    const { generatePdf, isLoadingGeneratePdf } = useGeneratePdf();
    const { cloneTest, isLoadingCloneTest } = useCloneHandTest();
    const { openAlertWindow, openAlertWindowForError } = useAlertWindow();
    const { currentTestSummary, isMyTestSummary, setCurrentTestSummaryById } = useCurrentTestSummary();
    const { canEditCurrentHandTest, setCurrentHandTestSumamaryById } = useSetCurrentHandTestSummary();
    const getDraftId = ():number => misc.toNumberOrNull( qs.parse( location.search ).id as string );
    const testId:number = getDraftId();
    const isTestEditable = canEditCurrentHandTest || catModel.isWorkingCatAnalysis( currentCatAnalysis ) 
        || isMyTestSummary;

    /**
     * /edit-test?id={testId}      <-- calls getTest(testId)
     * /edit-test                  <-- calls createCatAnalysis if currentCatAnalysis is empty
     */

    useEffect(
        () => {
            if ( !_.isNil( testId ) ) {
                getHandTest( testId );
                setCurrentTestSummaryById( testId );
                setCurrentHandTestSumamaryById( testId );
            } 
            else if ( _.isEmpty( currentCatAnalysis ) ) {
                createCatAnalysis();
            }
        },
        [ testId ]
    );

    const mapValidationErrorsToAlertData = (res:ValidateTestResponse): AlertData => {
        return {
            title: 'Validation Failed',
            text: _.join( res.errors, "\n" ),
        };
    };

    const onClickValidateTest = (event:any) => {
        event.preventDefault();
        return validateHandTest( currentHandTestAnalysys )( currentCatAnalysis )
            .then( sf.thru_if( (res:ValidateTestResponse) => !!! res.valid )(
                (res:ValidateTestResponse) => { throw mapValidationErrorsToAlertData( res ); }
            ))
            .then( sf.tap( () => openAlertWindow( { title: 'Success', text: 'The form data is valid.' } ) ) )
            .catch( sf.tap( openAlertWindowForError ) );
    };

    const onClickSaveHandTest = (event:any) => {
        event.preventDefault();
        
        return saveHandTest( testId, currentHandTestAnalysys, currentCatAnalysis )
            .then( (res:DraftTestSummary) => history.replace( `/${routeEditHand}?id=${res.draftId}`) )
            .then( sf.tap( () => openAlertWindow( { title: 'Success', text: 'The test has been saved.' } ) ) )
            .catch( sf.tap( openAlertWindowForError ) )
            .catch( console.error );
    };

    const onClickCloneTest = (event:any) => {
        event.preventDefault();
        return cloneTest( testId )
            .then( () => history.push( `/${routeEditHand}` ) )
            .then( sf.pause( 10 ) )
            .then( sf.tap( () => openAlertWindow( { title: 'Test Cloned', text: 'You can now make edits and save the copy as a new test.' } ) ) )
            .catch( sf.tap( openAlertWindowForError ) );
    };

    const onClickGeneratePdf = (event:any) => {
        event.preventDefault();
        return onClickGeneratePdfEdge(event);
    };

    const onClickGeneratePdfEdge = (event:any) => {
        return Promise.resolve( null )
            .then( 
                () => isTestEditable 
                    ? saveHandTest( testId, currentHandTestAnalysys, currentCatAnalysis )
                    : Promise.resolve({ draftId: testId })
            )
            .then( sf.tap( (res:DraftTestSummary) => history.replace( `/${routeEditHand}?id=${res.draftId}`) ) )
            .then( (res:DraftTestSummary) => generatePdf( res.draftId, currentHandTestAnalysys, currentCatAnalysis )
            .then( 
                (blob:Blob) => {
                    const fileName = `Test${ res.draftId }.pdf`;
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    }
                    // Force browser to show downloaded content as file
                    else if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, fileName);
                    } 
                    // old versions
                    // https://stackoverflow.com/questions/18755750/saving-text-in-a-local-file-in-internet-explorer-10
                    else {
                        const link = window.document.createElement("a");
                        if (link.download !== undefined) {
                            link.setAttribute("href", URL.createObjectURL(blob));
                            link.setAttribute("download", fileName);
                            link.style.visibility = 'hidden';
                            window.document.body.appendChild(link);
                            link.click();
                            window.document.body.removeChild(link);
                        } else {
                            throw new Error('Internal Error: document.createElement(a) returned undefined')
                        }
                    }
                } 
            )
            .then( () => { getHandTest( res.draftId ) } )
            .catch( 
                (err) => {
                    if ( _.get( err, 'json.value.valid' ) === false ) {
                        throw mapValidationErrorsToAlertData( err.json.value ); 
                    } else {
                        throw err;
                    }
                }
            )
            .catch( sf.tap( openAlertWindowForError ) ) );
    };

    const onClickCancel = () => {
        props.history.replace(`/${routeHandTests}`);
    };

    if ( _.isEmpty( currentCatAnalysis ) || isLoadingGetState ) {
        return (
            <PageContainer isLoading={true}>
                <Loading mt={8} isLoading={true} />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <TitleH4>
                Hand Test / Client Analysis 
            </TitleH4>
            {
                !!! isTestEditable && 
                <Box mt={1}>
                <SubTitle color={'error'}>
                    { '* READ-ONLY: you can only make changes to tests you created'}
                </SubTitle>
                </Box>
            }
            
            <Box mt={3} border={1} borderColor={theme.palette.text.primary}>
                <TabGeneralInfo
                    isSavedTest={ !_.isNil( testId ) }
                />
                <TabFilmInfo
                    filmInfoMap={currentCatAnalysis.filmInfoMap} handType
                />
                {/* <TabReportData /> */}
            </Box>
 
            <Box mt={3} mb={5}>
            <Grid container direction={'row-reverse'} spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Button
                        onClick={ onClickGeneratePdf }
                        disabled={ isLoadingGeneratePdf }
                        isLoading={ isLoadingGeneratePdf }
                        fullWidth
                        startIcon={<ArrowDownwardSharpIcon style={{ fontSize: 18 }}/>}
                        >
                        PDF
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        onClick={ onClickValidateTest }
                        isLoading={ isLoadingValidateTest }
                        disabled={ isLoadingValidateTest }
                        fullWidth
                        >
                        Validate 
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        onClick={ onClickSaveHandTest }
                        isLoading={ isLoadingSaveTest }
                        disabled={ !!! isTestEditable }
                        fullWidth
                        >
                        Save 
                    </Button>
                </Grid>
                    <Grid item xs={12} sm={2}>
                        {
                            isTestEditable &&
                            <Button
                                onClick={ onClickCancel }
                                fullWidth
                                color={'secondary'}
                                >
                                Cancel 
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={12} sm={2} />
                    <Grid item xs={12} sm={2}>
                        {
                            !!! catModel.isWorkingCatAnalysis( currentCatAnalysis ) &&
                            <Button
                                onClick={ onClickCloneTest }
                                isLoading={ isLoadingCloneTest }
                                fullWidth
                                >
                                Clone 
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
        </PageContainer>
    );
};

export default connect(
    withAll( withCatAnalysesState, withFormOptionsState, withHandTestFormState ),
    withAll( withCatAnalysesDispatch, withSessionDispatch, withHandTestSelectors ),
)( EditHandTestPage );
