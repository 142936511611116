import React  from 'react';
import TextField from '../../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import {
    GeneralInfo,
    stateCurrentModel,
    stateGeneralInfo
} from '../../state/handTest/models';
import { updateGeneralInfoField } from '../../state/handTest/actions'
import { timeFormat } from '../../utils';
import moment from 'moment';
import _ from 'lodash'; 
import MomentUtils from '@date-io/moment';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%'
    },
}));

interface Props {
    generalInfo:GeneralInfo,
    updateGeneralInfoField:any
};

const FormGeneralInfo:React.FC<Props> = (props:Props) => {
    const classes = useStyles();
    const targetState = props[stateGeneralInfo];
    const updateState = props.updateGeneralInfoField;

    return (
        <Box width={1}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="name"
                            label="Test Name"
                            value={ targetState.name }
                            onChange={ event => updateState('name', event.target.value) }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider
                            libInstance={ moment }
                            utils={ MomentUtils }
                        >
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format={ timeFormat }
                                fullWidth
                                id="testDateTime"
                                label="Test Date"
                                value={ targetState.testDateTime }
                                onChange={ event => updateState('testDateTime', event.format(timeFormat)) }
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            multiline
                            autogrow
                            inputProps={{
                                maxLength: 1000,
                            }}
                            rows={ Math.max( 2, _.defaultTo( _.split( targetState.notes, "\n" ), []).length ) }
                            name="notes"
                            label="Notes (1000 characters maximum)"
                            value={ targetState.notes }
                            onChange={ event => updateState('notes', event.target.value) }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ targetState.includeNotesOnReport }
                                    onChange={ event => updateState('includeNotesOnReport', event.target.checked) }
                                    color="primary"
                                />
                            }
                            label="Check this box to include Notes on the report."
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

const mapStateToProps = (state, ownProps?) => ({
    [stateGeneralInfo]: state?.model?.handTest?.[stateCurrentModel]?.[stateGeneralInfo]
})

export default connect(
    mapStateToProps,
    {
        updateGeneralInfoField,
    }
)(FormGeneralInfo);