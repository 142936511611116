export const ADD_GENERAL_INFO       = 'ADD_GENERAL_INFO' as const;
export const ADD_CUSTOMER_INFO      = 'ADD_CUSTOMER_INFO' as const;
export const ADD_FILM_TEST_CONFIG   = 'ADD_FILM_TEST_CONFIG' as const;
export const ADD_REPORT_DATA        = 'ADD_REPORT_DATA' as const;

export const UPDATE_GENERAL_INFO     = 'UPDATE_GENERAL_INFO' as const;
export const UPDATE_CUSTOMER_INFO    = 'UPDATE_CUSTOMER_INFO' as const;
export const UPDATE_FILM_TEST_CONFIG = 'UPDATE_FILM_TEST_CONFIG' as const;
export const UPDATE_REPORT_DATA      = 'UPDATE_REPORT_DATA' as const;

export const CHANGE_FORM_LOADING_STATE     = 'CHANGE_FORM_LOADING_STATE' as const;
export const CHANGE_GET_FORM_LOADING_STATE = 'CHANGE_GET_FORM_LOADING_STATE' as const;
export const CHANGE_SUMMARY_LOADING_STATE  = 'CHANGE_SUMMARY_LOADING_STATE' as const;
export const CHANGE_GET_PDF_LOADING_STATE  = 'CHANGE_GET_PDF_LOADING_STATE' as const;
export const CHANGE_CLONE_TEST_LOADING_STATE  = 'CHANGE_CLONE_TEST_LOADING_STATE' as const;

export const SET_HAND_TEST_SUMMARIES = 'SET_HAND_TEST_SUMMARIES' as const;
export const SET_HAND_TEST_SUMMARIES_PAGINATION = 'SET_SUMMARIES_TEST_PAGINATION' as const;
export const SET_HAND_TEST_SUMMARIES_FILTER = 'SET_HAND_TEST_SUMMARIES_FILTER' as const;
export const SET_HAND_TEST_SUMMARIES_SEARCH_TEXT = 'SET_HAND_TEST_SUMMARIES_SEARCH_TEXT' as const;

export const SET_HAND_TEST = 'SET_HAND_TEST' as const;
export const REMOVE_CURRENT_HAND_FILM_TEST = 'REMOVE_CURRENT_HAND_FILM_TEST' as const;

export const SET_CURRENT_HAND_TEST_BY_ID = 'SET_CURRENT_HAND_TEST_BY_ID' as const;

export const SET_PRODUCTS = 'SET_PRODUCTS' as const;
