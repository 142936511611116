/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import { 
    createStyles,
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    LoggerFactory,
    sf,
} from '../utils';
import _ from 'lodash';
import {
    asIdStr,
} from '../state';
import { 
    TestMethod,
} from '../services/paragon';
const logger = LoggerFactory( 'TestMethodSelect' );

const useStyles = makeStyles((theme: Theme) => ({
    ...createStyles({
      formControl: {
        // margin: theme.spacing(1),
        // minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    }),
}));

interface Props {
    testMethods: TestMethod[];
    value: any;
    onChange: (testMethod:TestMethod) => any;
};

const TestMethodSelect:React.FC<Props> = (props:Props) => {
    const {
        testMethods,
        value,
        onChange,
    } = props;
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);
    const classes = useStyles();
    logger.log( 'render', { props });
    return (
        <FormControl 
            variant="outlined" 
            className={classes.formControl}
            fullWidth
            >
            <InputLabel ref={inputLabel} htmlFor="testMethod">
                TestMethod
            </InputLabel>
            <Select
                native
                value={ value }
                onChange={ 
                    _.flow( 
                        sf.lens( 'target.value' ).get,
                        _.toNumber,
                        (id:number) => _.find( testMethods, (testMethod:TestMethod) => testMethod.id === id ),
                        onChange,
                    )
                }
                labelWidth={labelWidth}
                inputProps={{
                    name: 'testMethod',
                    id: 'testMethod',
                }}
                >
                <option 
                    key={ asIdStr(0) } 
                    value={0}
                    >
                </option>
                {
                    _.map( 
                        testMethods,
                        (testMethod:TestMethod) => (
                            <option 
                                key={ asIdStr(testMethod.id) } 
                                value={ testMethod.id }
                                >
                                { testMethod.name } 
                            </option>
                        )
                    )
                }
            </Select>
        </FormControl>
    )
};

export default TestMethodSelect ;