import React from 'react';
import SignUpForm  from '../comps/SignUpForm';
import {
    routeMachineTests,
    sf,
 } from '../utils';
import { 
    withSessionState,
    withSessionDispatch,
    connect,
    useAlertWindow,
} from '../state';
import { 
    RegisterRequest 
} from '../types';
import _ from 'lodash';
import SignInContainer from '../comps/SignInContainer';
import SignUpFormBox from '../comps/SignUpFormBox';

const SignUpPage: React.FC<any> = (props:any) => {
    const {
        register,
    } = props;

    const [ isLoading, setIsLoading ] = React.useState(false);
    const { openAlertWindowForError } = useAlertWindow();

    const onSubmit = (event:any, data:RegisterRequest ) => {
        event.preventDefault();
        setIsLoading(true);
        
        return register( data )
            .then( sf.tap( _.partial( setIsLoading, false ) ))
            .then( () => props.history.push(`/${routeMachineTests}`) )
            .then( sf.tap_throw( _.partial( setIsLoading, false ) ))
            .catch( sf.tap_throw( _.partial( setIsLoading, false ) ))
            .catch( sf.tap( openAlertWindowForError ) );
    };

    return (
        <SignInContainer>
            <SignUpFormBox title="REGISTER">
                <SignUpForm
                    onSubmit={ onSubmit }
                    isLoading={ isLoading }
                />
            </SignUpFormBox>
        </SignInContainer>
    );
};

export default connect( 
    withSessionState,
    withSessionDispatch
)( SignUpPage );

